<template>
  <div class="table-wrapper pb-12">
    <div class="cover" v-if="loading"></div>
    <div class="loader" v-if="loading">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <vuetable
      ref="datatable"
      :fields="columns"
      :per-page="per_page"
      :api-url="endpoint"
      data-path="data"
      pagination-path=""
      :http-options="httpOptions"
      :css="cssConfig.table"
      @vuetable:loading="onLoading()"
      @vuetable:loaded="onLoaded()"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:load-success="onLoadSuccess"
    >
      <!-- Level -->
      <div slot="level-slot" slot-scope="props">
        <router-link
          :to="{
            name: 'JobsSingle',
            params: { id: props.rowData.jobFamily },
          }"
        >
          <div class="pt-2">
            <span class="text-sm md:text-base">{{
              props.rowData.jobTitle
            }}</span>
            <div
              class="text-sm pb-2 text-black-light"
              v-if="props.rowData.jobFamily"
            >
              <small class="font-light"
                >{{ getJobFamily(props.rowData.jobFamily) }}
              </small>
            </div>
          </div>
        </router-link>
      </div>

      <!-- Company -->
      <div slot="company-slot" slot-scope="props">
        <div class="text-left pt-2">
          <router-link
            :to="{
              name: 'CompanySingle',
              params: { companyName: props.rowData.companyName },
            }"
          >
            <div>
              <span class="text-sm md:text-base">{{
                props.rowData.companyName
              }}</span>
            </div>
          </router-link>
          <div class="text-sm pb-2 text-black-light">
            <small class="font-thin"
              >{{ props.rowData.jobLocation }} |
              {{ dateChunks(props.rowData.createdAt)[0] }}
            </small>
          </div>
        </div>
      </div>

      <!-- Experience -->
      <div slot="experience-slot" slot-scope="props">
        <div class="pt-2 text-left">
          <span>{{ props.rowData.expAtCompany }}</span>
          /
          <span v-if="props.rowData.totalExp">
            {{ props.rowData.totalExp }}
          </span>
          <span class="text-sm" v-else>
            NA
          </span>
        </div>
      </div>

      <!-- Total Salary -->
      <div slot="totalsalary-slot" class="pt-2" slot-scope="props">
        <div class="pt-2 text-left">
          <div>
            <p class="text-sm md:text-base">
              ₦ {{ formatTotal(props.rowData.totalYearlyComp) }}
            </p>
          </div>
          <div class="text-sm pb-2 text-black-light">
            <small class="font-thin"
              >{{ formatStock(props.rowData.salaryYearly) }} |
              <span v-if="props.rowData.stockGrantYearly"
                >{{ formatStock(props.rowData.stockGrantYearly) }}
              </span>
              <span v-else>No</span> |
              <span v-if="props.rowData.bonusYearlyAvg">{{
                formatStock(props.rowData.bonusYearlyAvg)
              }}</span>
              <span v-else>No</span>
            </small>
          </div>
        </div>
      </div>
    </vuetable>

    <div class="pt-4 flex flex-wrap justify-between">
      <div class="flex mb-3 md:mb-0 items-center space-x-2">
        <div>
          <select
            name="perPage"
            class="rounded"
            @change="perPageChanged($event)"
          >
            <option
              v-for="(option, index) in perPageOptions"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <small>rows per page</small>
      </div>
      <vuetable-pagination
        :css="cssConfig.pagination"
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2";
import { FILTERS } from "@/helpers/filters";
import { jobfamilyGroups } from "@/helpers/enums";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import { http } from "@/helpers/http";

export default {
  name: "SalariesTable",
  components: { Vuetable, VuetablePagination },
  props: {
    id: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    custom_class: {
      type: String,
    },
    responsive: {
      type: Boolean,
    },
    per_page: {
      type: Number,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    search_fields: {
      type: Array,
    },
    color_scheme: {
      type: String,
    },
  },
  data() {
    return {
      searchKeyword: null,
      cssConfig: {
        table: {
          tableWrapper: "vuetable-wrapper bg-white w-full table",
          tableHeaderClass: "mb-0 px-4 text-left",
          tableBodyClass: "mb-0",
          tableClass: "_datatable border border-gray-border w-full px-6",
          ascendingIcon: "fas fa-sort-amount-up-alt",
          descendingIcon: "fas fa-sort-amount-down-alt",
          ascendingClass: "sorted-asc",
          descendingClass: "sorted-desc",
          sortableIcon: "fas fa-sort-amount-down-alt",
          detailRowClass: "vuetable-detail-row",
          handleIcon: "fas fa-bars text-secondary",
          renderIcon(classes, options) {
            return `<i class="ml-1 ${classes.join(" ")}"></span>`;
          },
        },
        pagination: {
          wrapperClass: "flex border bg-white border-gray-border rounded",
          activeClass: "active bg-purple text-white",
          disabledClass: "disabled",
          pageClass:
            " px-2 border border-collapse border-gray-border flex items-center btn-nav font-small cursor-pointer",
          linkClass:
            "btn-nav border border-collapse border-gray-border  page-link",
          paginationClass: "bg-white",
          paginationInfoClass: "float-left",
          dropdownClass: "form-control",
          icons: {
            first: "fas fa-chevron-double-left text-xs",
            prev: "fas fa-chevron-left text-xs",
            next: "fas fa-chevron-right text-xs",
            last: "fas fa-chevron-double-right text-xs",
          },
        },
      },
      perPageOptions: [5, 10, 15, 20],
      httpOptions: {
        baseURL: http.defaults.baseURL,
      },
      loading: true,
    };
  },
  methods: {
    dateChunks(dateTime) {
      return FILTERS.formatDateTime(dateTime).split(",");
    },
    formatTotal(value) {
      return FILTERS.formatTotalSalary(value);
    },
    formatStock(value) {
      return FILTERS.formatTotalSalary(value);
    },
    onLoadSuccess(resp) {
      this.$emit("loadSuccess", resp);
    },
    onLoading() {
      this.loading = true;
    },
    onLoaded(d) {
      this.loading = false;
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.datatable.changePage(page);
    },
    getJobFamily(jobFamilyNumber) {
      return jobfamilyGroups(jobFamilyNumber);
    },
    perPageChanged(event) {
      this.$emit("per-page-changed", event.target.value);
    },
  },
};
</script>

<style  lang="scss">
.table-wrapper {
  position: relative;
  min-height: 10rem;
  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    opacity: 0.5;
    z-index: 2;
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: #574ca5;
  }
}

.vuetable thead {
  --tw-bg-opacity: 0.25 !important;
  @apply bg-purple;
  @apply p-4;

  th {
    @apply text-black-brown;
    @apply text-left;

    @media screen and (max-width: 640px) {
      padding: 10px 5px;
    }
    padding: 20px 20px;
  }
}

.vuetable-slot {
  padding: 0 20px;

  @media screen and (max-width: 640px) {
    padding: 10px 5px;
  }
}

tbody tr:nth-child(even) {
  @apply bg-gray-main;
}

.tn-nav:last-of-type {
  @apply rounded-r-none;
}

.page-link {
  padding: 10px;
}

.font-small {
  font-size: 80%;
}

@media screen and (max-width: 1024px) {
  .vuetable-th-slot-experience-slot {
    display: none;
  }
}
</style>